<template>
  <div>
    <div class="tw-p-6 tw-text-gray-700 tw-text-left tw-font-bold tw-text-xl">
      Log de erros
    </div>
    <div
      class="tw-p-0 tw-items-center tw-justify-center sm:tw-flex tw-grid tw-grid-cols-1"
    >
      <div class="tw-text-center px-2">
        <v-select
          solo
          class="tw-max-w-md mx-auto"
          name="name"
          label="Selecione o totem"
          :items="getTotens"
          item-text="nome"
          item-value="id"
          v-model="filtro.totem"
          :disabled="loading"
          hide-details
        ></v-select>
      </div>
      <div class="tw-text-center tw-mt-3 sm:tw-mt-0">
        <button
          @click="buscarLogs()"
          :disabled="loading"
          class="tw-bg-pink-600 tw-text-white tw-py-3 tw-px-4 tw-m-2 tw-rounded disabled:tw-bg-gray-100 disabled:tw-text-gray-500 tw-shadow-md"
        >
          Consultar
        </button>
      </div>
    </div>
    <div
      class="tw-text-sm tw-text-gray-600 tw-text-center tw-my-2"
      v-if="listaLogs.length == 0"
    >
      Serão exibidas os 50 ultimos erros
    </div>
    <vListaLogs v-if="listaLogs.length > 0" :lista="listaLogs" />
  </div>
</template>

<script>
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
} from "@firebase/firestore";
import { db } from "@/service/firebase";
import vListaLogs from "./lista.vue";
export default {
  components: {
    vListaLogs,
  },
  data() {
    return {
      loading: false,
      filtro: {
        totem: null,
      },
      listaLogs: [],
    };
  },
  methods: {
    async buscarLogs() {
      this.loading = true;
      this.listaLogs = [];
      let referencia = collection(db, "erros");
      let queryng = query(
        referencia,
        where("totem_id", "==", this.filtro.totem),
        orderBy("time", "desc"),
        limit(50)
      );
      const snapshot = await getDocs(queryng);

      snapshot.forEach((element) => {
        this.listaLogs.push(element.data());
      });
      this.loading = false;
    },
  },
  computed: {
    getTotens() {
      return this.$store.getters["dashboard/getTotens"];
    },
  },
};
</script>
