<template>
  <div>
    <div v-if="listaLogs.length > 0">
      <div class="tw-p-2 tw-py-3 tw-mb-5 tw-text-center tw-text-red-400">
        Total de erros encontrados,
        <span class="tw-font-bold">{{ listaLogs.length }}</span> erros/avisos.
      </div>
    </div>
    <div class="tw-items-center tw-justify-center tw-text-center">
      <div
        id="list"
        class="tw-bg-transparent sm:tw-bg-white tw-p-0 sm:tw-p-5 tw-block tw-shadow-lg tw-mx-auto tw-w-12/12 md:tw-w-11/12"
      >
        <div
          class="tw-p-1 tw-py-3 tw-hidden sm:tw-grid sm:tw-grid-cols-12 tw-font-bold"
        >
          <div class="tw-col-span-2">Data/Hora</div>
          <div class="tw-col-span-4">Error</div>
          <div class="tw-col-span-3">Rota</div>
          <div class="tw-col-span-3">Component</div>
        </div>
        <div
          class="sm:tw-hidden tw-block tw-font-normal tw-text-lg"
          v-if="listaLogs.length == 0"
        >
          Sem resultados
        </div>
        <div
          class="item tw-text-gray-700 tw-text-font-normal tw-text-sm"
          :key="index"
          v-for="(log, index) in listaLogs"
        >
          <div
            class="tw-col-span-1 sm:tw-col-span-2 tw-text-center sm:tw-text-left"
          >
            <div class="titulo">DATA/HORA s</div>
            <div>
              {{ log.time | CalendarDate }}
            </div>
            <div>
              {{ log.time | CalendarHora }}
            </div>
          </div>
          <div class="tw-col-span-1 sm:tw-col-span-4">
            <div class="titulo">Erro</div>
            {{ log.error | ExisteDados }}
          </div>
          <div class="tw-col-span-1 sm:tw-col-span-3">
            <div class="titulo">Rota</div>
            <div>
              {{ log.route | ExisteDados }}
            </div>
          </div>
          <div class="tw-col-span-1 sm:tw-col-span-3">
            <div class="titulo">Tracker</div>
            <div>
              {{ log.info | ExisteDados }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lista: Array,
  },
  filters: {},
  computed: {
    listaLogs() {
      return this.lista;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  @apply tw-m-2 sm:tw-m-0 tw-p-3 tw-items-start sm:tw-items-center tw-grid tw-grid-cols-1 sm:tw-grid-cols-12 tw-font-body tw-text-sm tw-break-words tw-rounded-md sm:tw-bg-transparent tw-bg-white sm:tw-shadow-none tw-shadow-lg;
  .titulo {
    @apply tw-block sm:tw-hidden tw-p-2 tw-font-bold;
  }
  &:nth-last-child(odd) {
    @apply sm:tw-bg-white;
  }
  &:nth-last-child(even) {
    @apply sm:tw-bg-gray-50;
  }
}
</style>
